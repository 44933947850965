import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { getBrandFromHost } from './modules/brandInfo';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

let environment: string | undefined = undefined;

if (isProdBuild) {
    environment = 'production';
} else if (isDevBuild) {
    environment = 'development';
}

if (/-staging|-qa\d+\./.test(window.location.host)) {
    environment = 'staging';

    // if this is QA, lets set that environment more specifically
    const qaRegex = /booking-(qa\d+)\./;
    const match = window.location.host.match(qaRegex);

    if (match) {
        environment = match[1];
    }
}

const brandInfo = getBrandFromHost(window.location.host);

// render out brand specific GTM script - good lord this is hacky
const script = document.createElement('script');
script.innerHTML = brandInfo.gtmScript;
document.head.appendChild(script);

window.dataLayer = window.dataLayer || [];

if (!window.gtag) {
    window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
    };
}

declare const hahVersion: string;

console.debug('🐛 init sentry inside of booking-subdomain spa', { devBuild: isDevBuild });

if (!isDevBuild) {
    Sentry?.init({
        dsn: 'https://51ac5277caed485da455f312b862edee@o98567.ingest.sentry.io/6326087', // mcheckout-rjs
        integrations: [
            Sentry.browserTracingIntegration({ enableInp: true }),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [window.location.origin],
            }),
        ],

        environment: environment,
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /sumo\.com/i,
            /wistia\.com/i,
            /gstatic\.com\/call-tracking\//i,
            /bing\.com/i,
            /doubleclick\.net/i,
            /dca0\.com/i, // no idea what this garbage is
            /clickcease\.com/i,
            /googletagmanager\.com/i,
        ],
        beforeSend: (event) => {
            if (event && event.message && event.message.match(/401 Unauthorized/i)) {
                event.level = 'warning'; // dont log this as an error
            }
            if (event && event.message && event.message.match(/No connection/i)) {
                event.level = 'warning'; // dont log this as an error
            }

            return event;
        },
        release: `booking-subdomain-index@${hahVersion}`,

        tracesSampleRate: 0.01, // 1%
        replaysSessionSampleRate: 0.10, // 10%
        replaysOnErrorSampleRate: 0.5, // 50% when there's an error
    });
}

ReactDOM.render(
    <BrowserRouter basename={baseUrl!}>
        <App />
    </BrowserRouter>,
    rootElement
);
