import * as Sentry from '@sentry/browser';


export interface CancellablePromise<T> extends Promise<T & { cancelled?: true }> {
    abort(): void;
}

interface IProblemJsonFormat {
    traceId: string;
    type: string;
    title: string;
    status: number;
    errors: { [key: string]: string[] };
}

/**
 * Base class for errors that contain the web server's response text or JSON object.
 */
class mcErrorWithResponseContent extends Error {
    public responseContent: any | string;
    public url: string;
    public statusCode: number;

    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message);
        this.url = url;
        this.statusCode = statusCode;
        this.message = `[${statusCode}] ${removeQueryString(url)}: ${message}`;
        this.responseContent = responseContent;
    }
}

/**
 * Custom wrapper around 400 Bad Request HTTP errors that are the "problem json" content type so we can handle them better in error handler.
 */
export class mcProblemJsonFetchError extends mcErrorWithResponseContent {
    static Name = 'ProblemJsonFetchError';
    public webApiProblem: IProblemJsonFormat;

    constructor(statusCode: number, url: string, responseContent: any | string) {
        super('', statusCode, url, responseContent);
        this.name = mcProblemJsonFetchError.Name;
        this.webApiProblem = responseContent as IProblemJsonFormat;

        // should NOT be +=
        this.message = this.webApiProblem.title;
        if (this.webApiProblem.errors != null) {
            const messages = new Array<string>();
            this.message += ': <br/><br/>';

            for (const err in this.webApiProblem.errors) {
                const curErrKey = this.webApiProblem.errors[err];
                for (const subErr of curErrKey) {
                    messages.push(subErr);
                }
            }
            this.message += '<ul>';
            for (const errLocal of messages) {
                this.message += `<li>${errLocal}</li>`;
            }
            this.message += '</ul>';
        }
    }
}

/**
 * Custom wrapper around 400 Bad Request HTTP errors so we can handle them better in error handler.
 */
export class mcBadRequestFetchError extends mcErrorWithResponseContent {
    static Name = 'BadRequestFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcBadRequestFetchError.Name;
    }
}

/**
 * Custom wrapper around 401 Unauthorized HTTP errors so we can handle them better in error handler.
 */
export class mcUnauthorizedFetchError extends mcErrorWithResponseContent {
    static Name = 'UnauthorizedFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcUnauthorizedFetchError.Name;
    }
}

/**
 * Custom wrapper around 403 Forbidden HTTP errors so we can handle them better in error handler.
 */
export class mcForbiddenFetchError extends mcErrorWithResponseContent {
    static Name = 'ForbiddenFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcForbiddenFetchError.Name;
    }
}

/**
 * Custom wrapper around 404 Not Found HTTP errors so we can handle them better in error handler.
 */
export class mcNotFoundFetchError extends mcErrorWithResponseContent {
    static Name = 'NotFoundFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcNotFoundFetchError.Name;
    }
}

/**
 * Custom wrapper around 410 Gone HTTP errors so we can handle them better in error handler.
 */
export class mcGoneFetchError extends mcErrorWithResponseContent {
    static Name = 'GoneFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcGoneFetchError.Name;
    }
}

/**
 * Custom wrapper around 422 Gone HTTP errors so we can handle them better in error handler.
 */
export class mcUnprocessableEntityFetchError extends mcErrorWithResponseContent {
    static Name = 'UnprocessableEntityFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcUnprocessableEntityFetchError.Name;
    }
}

/**
 * Custom wrapper around 500-range HTTP errors so we can handle them better in error handler.
 */
export class mcGenericFiveHundredFetchError extends mcErrorWithResponseContent {
    static Name = 'GenericFiveHundredFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: any | string) {
        super(message, statusCode, url, responseContent);
        this.name = mcGenericFiveHundredFetchError.Name;
    }
}

/**
 * Custom wrapper around generic non-JSON returned errors so we can handle them better in error handler.
 */
export class mcGenericNonJsonFetchError extends mcErrorWithResponseContent {
    static Name = 'GenericNonJsonFetchError';
    constructor(message: string, statusCode: number, url: string, responseContent: string) {
        super(message, statusCode, url, responseContent);
        this.name = mcGenericNonJsonFetchError.Name;
    }
}

/**
 * A custom wrapper around non-OK HTTP JSON errors returned from the server.
 */
export class mcReturnedJsonError extends Error {
    static Name = 'ReturnedJsonError';
    public ErrorObj: any;

    public StatusCode: number;

    public url: string;

    constructor(errorObj: any, statusCode: number, url: string) {
        super(undefined); // (1)
        this.name = mcReturnedJsonError.Name; // (2)

        this.ErrorObj = errorObj;

        this.StatusCode = statusCode;
        this.url = url;

        this.message = `[${statusCode}] ${removeQueryString(url)}: ${this.FormatErrorToMsg()}`;
    }

    private FormatErrorToMsg(): string {
        const error = this.ErrorObj;

        return error.ExceptionMessage || error.exceptionMessage || error.Message || error.message || 'Unknown error';
    }
}

let hahContext: string;
declare const hahVersion: string;
let requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Hah-Context': 'BookingSubdomain',
    'accept': 'application/json',
    'Hah-ReactVersion': hahVersion,
}
export function setHahContext(context: string) {
    hahContext = context;
    requestHeaders = {
        ...requestHeaders,
        'Hah-Context': context,
    };
}

export let unauthorizedRequestHandler = (response: Response) => { };
export let forbiddenRequestHandler = (response: Response) => { };
export function setUnauthorizedRequestHandler(handler: (response: Response) => void) {
    unauthorizedRequestHandler = handler || (() => { });
}
export function setForbiddenRequestHandler(handler: (response: Response) => void) {
    forbiddenRequestHandler = handler || (() => { });
}

export function getRequestHeaders() {
    return requestHeaders;
}

export function executePost<TResult, TPost = any>(url: string, postData?: TPost): CancellablePromise<TResult> {

    return executeFetch<TResult>(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: requestHeaders,
        body: JSON.stringify(postData),
    });

}

export function executeGet<TResult>(url: string): CancellablePromise<TResult> {

    return executeFetch<TResult>(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: requestHeaders,
    });

}

function removeQueryString(uri: string): string {
    // Finds the first '?' character and gets the substring before it.
    return uri.split('?')[0];
}

function executeFetch<TResult>(url: string, init: RequestInit): CancellablePromise<TResult> {

    const controller = new AbortController();

    const correlation_id = Math.random().toString(36).substring(2, 9);

    const currentScope = Sentry?.getCurrentScope();
    if (currentScope != null) {
        currentScope.setTag('correlation_id', correlation_id);
    }

    const initNew = { ...init, signal: controller.signal, headers: { ...init.headers, 'X-Correlation-ID': correlation_id } };

    const result = fetch(url, initNew).then(async response => {
        if (controller.signal.aborted) {
            return { cancelled: true } as any;
        }

        // per HAH-5179 - handle non-JSON response better
        const contentType = response.headers.get('content-type');

        const isRegularJson = contentType != null && contentType.indexOf('application/json') !== -1;
        const isProblemJson = contentType != null && contentType.indexOf('application/problem+json') !== -1;

        const getMsg = (isRegularJson || isProblemJson) ? response.json() : response.text();

        let msg: any = null;

        if (isProblemJson && response.status == 400) {
            // handle these special - this is webapi returning Bad Request probably, with specific error syntax
            msg = await getMsg;
            throw new mcProblemJsonFetchError(response.status, url, msg);
        }

        switch (response.status) {
            case 400:
                msg = await getMsg;
                throw new mcBadRequestFetchError('User attempted to access a URL and got a BadRequest status', response.status, url, msg);
            case 401:
                msg = await getMsg;
                unauthorizedRequestHandler(response);
                throw new mcUnauthorizedFetchError('User attempted to access a URL and got an Unauthorized status', response.status, url, msg);
            case 403:
                msg = await getMsg;
                forbiddenRequestHandler(response);
                throw new mcForbiddenFetchError('User attempted to access a URL and got a Forbidden status', response.status, url, msg);
            case 404:
                msg = await getMsg;
                throw new mcNotFoundFetchError('URL not found', response.status, url, msg);
            case 410:
                msg = await getMsg;
                throw new mcGoneFetchError('Request failed with Gone status', response.status, url, msg);
            case 422:
                msg = await getMsg;
                throw new mcUnprocessableEntityFetchError('Request failed with UnprocessableEntity status', response.status, url, msg);
            case 502:
                msg = await getMsg;
                throw new mcGenericFiveHundredFetchError('Bad Gateway', response.status, url, msg);
            case 524:
                msg = await getMsg;
                throw new mcGenericFiveHundredFetchError('Origin Timeout', response.status, url, msg);
            case 520:
                msg = await getMsg;
                throw new mcGenericFiveHundredFetchError('Web Server Returned an Unknown Error', response.status, url, msg);
        }

        if (!contentType || (!isRegularJson && !isProblemJson)) {
            msg = await getMsg;
            throw new mcGenericNonJsonFetchError('Server returned non-JSON response (' + contentType + ')', response.status, url, msg);
        }

        if (response.ok) {
            return getMsg as Promise<TResult>;
        }

        msg = await getMsg;

        throw new mcReturnedJsonError(msg, response.status, url);
    }, error => {
        if (!controller.signal.aborted) {
            throw error;
        }
        //debuglog('executeFetch aborted', {url});
        return { cancelled: true };
    }) as CancellablePromise<TResult>;

    result.abort = () => {
        controller.abort();
    }
    return result;

}