import { BrandType } from '../typewriter/enums';

// FUTURE: this really should come from our server. inside the initial HTML request if possible, otherwise, in a "settings" type API call at start of the SPA. But we have this centralized for a reason/benefit. Get this data into the product/brand info, and then serialize that and call it a day.

const gtmScriptMovingStaffers = '!function(){"use strict";function l(e){for(var t=e,r=0,n=document.cookie.split(";");r<n.length;r++){var o=n[r].split("=");if(o[0].trim()===t)return o[1]}}function s(e){return localStorage.getItem(e)}function u(e){return window[e]}function A(e,t){e=document.querySelector(e);return t?null==e?void 0:e.getAttribute(t):null==e?void 0:e.textContent}var e=window,t=document,r="script",n="dataLayer",o="https://web.movingstaffers.com",a="",i="c5jwxkmqemi",c="3rnh=aWQ9R1RNLUtNWlNGRFA%3D&page=3",g="cookie",v="gtusr",E="",d=!1;try{var d=!!g&&(m=navigator.userAgent,!!(m=new RegExp("Version/([0-9._]+)(.*Mobile)?.Safari.").exec(m)))&&16.4<=parseFloat(m[1]),f="stapeUserId"===g,I=d&&!f?function(e,t,r){void 0===t&&(t="");var n={cookie:l,localStorage:s,jsVariable:u,cssSelector:A},t=Array.isArray(t)?t:[t];if(e&&n[e])for(var o=n[e],a=0,i=t;a<i.length;a++){var c=i[a],c=r?o(c,r):o(c);if(c)return c}else console.warn("invalid uid source",e)}(g,v,E):void 0;d=d&&(!!I||f)}catch(e){console.error(e)}var m=e,g=(m[n]=m[n]||[],m[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),t.getElementsByTagName(r)[0]),v=I?"&bi="+encodeURIComponent(I):"",E=t.createElement(r),f=(d&&(i=8<i.length?i.replace(/([a-z]{8}$)/,"kp$1"):"kp"+i),!d&&a?a:o);E.async=!0,E.src=f+"/"+i+".js?"+c+v,null!=(e=g.parentNode)&&e.insertBefore(E,g)}();';

const gtmScriptSml = '!function(){"use strict";function l(e){for(var t=e,r=0,n=document.cookie.split(";");r<n.length;r++){var o=n[r].split("=");if(o[0].trim()===t)return o[1]}}function s(e){return localStorage.getItem(e)}function u(e){return window[e]}function A(e,t){e=document.querySelector(e);return t?null==e?void 0:e.getAttribute(t):null==e?void 0:e.textContent}var e=window,t=document,r="script",n="dataLayer",o="https://web.simplemovinglabor.com",a="",i="c5jwxkmqemi",c="3rnh=aWQ9R1RNLVRQMjZHSlg%3D&page=3",g="cookie",v="gtusr",E="",d=!1;try{var d=!!g&&(m=navigator.userAgent,!!(m=new RegExp("Version/([0-9._]+)(.*Mobile)?.Safari.").exec(m)))&&16.4<=parseFloat(m[1]),f="stapeUserId"===g,I=d&&!f?function(e,t,r){void 0===t&&(t="");var n={cookie:l,localStorage:s,jsVariable:u,cssSelector:A},t=Array.isArray(t)?t:[t];if(e&&n[e])for(var o=n[e],a=0,i=t;a<i.length;a++){var c=i[a],c=r?o(c,r):o(c);if(c)return c}else console.warn("invalid uid source",e)}(g,v,E):void 0;d=d&&(!!I||f)}catch(e){console.error(e)}var m=e,g=(m[n]=m[n]||[],m[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),t.getElementsByTagName(r)[0]),v=I?"&bi="+encodeURIComponent(I):"",E=t.createElement(r),f=(d&&(i=8<i.length?i.replace(/([a-z]{8}$)/,"kp$1"):"kp"+i),!d&&a?a:o);E.async=!0,E.src=f+"/"+i+".js?"+c+v,null!=(e=g.parentNode)&&e.insertBefore(E,g)}();';

const brands = [
    {
        brand: BrandType.SmlMarketplace,
        companyName: 'Simple Moving Labor',
        abbrev: 'sml',
        icon: 'sml-favicon.ico',
        logoUrl: '/img/logos/sml-marketplace-stacked-color-logo.png',
        brandPhone: '1 (866) 767-5348',
        cleanPhone: '1-866-767-5348',
        addressLine1: '3700 Rufe Snow Dr.',
        addressLine2: 'Ft. Worth, TX 76180',
        phoneNumbers: ['(866) 767-5348', '(866) 767-5348'],
        gtmScript: gtmScriptSml,
    },
    {
        brand: BrandType.MsMarketplace,
        companyName: 'Moving Staffers',
        abbrev: 'ms',
        icon: 'ms-favicon.ico',
        logoUrl: '/img/logos/movingstaffers-marketplace-stacked-color-logo.png',
        brandPhone: '1 (800) 658-7292',
        cleanPhone: '1-800-658-7292',
        addressLine1: 'P.O. Box 8367',
        addressLine2: 'Wichita Falls, TX 76307-8367',
        phoneNumbers: ['(800) 570-2052', '(800) 658-7292'],
        gtmScript: gtmScriptMovingStaffers,
    },
    {
        brand: BrandType.SimpleMovingLabor,
        companyName: 'Simple Moving Labor',
        abbrev: 'sml',
        icon: 'sml-favicon.ico',
        logoUrl: '/img/sml-logo.png',
        brandPhone: '1 (866) 767-5348',
        cleanPhone: '1-866-767-5348',
        addressLine1: '3700 Rufe Snow Dr.',
        addressLine2: 'Ft. Worth, TX 76180',
        phoneNumbers: ['(866) 767-5348', '(866) 767-5348'],
        gtmScript: gtmScriptSml,
    },
    {
        brand: BrandType.MovingStaffers,
        companyName: 'Moving Staffers',
        abbrev: 'ms',
        icon: 'ms-favicon.ico',
        logoUrl: '/img/ms-logo.png',
        brandPhone: '1 (800) 658-7292',
        cleanPhone: '1-800-658-7292',
        addressLine1: 'P.O. Box 8367',
        addressLine2: 'Wichita Falls, TX 76307-8367',
        phoneNumbers: ['(800) 570-2052', '(800) 658-7292'],
        gtmScript: gtmScriptMovingStaffers,
    }
];


export function getBrandFromHost(host: string, brandType?: BrandType): BrandInfo {
    if (brandType) {
        const result = brands.find(b => b.brand === brandType);
        if (result) {
            return result;
        }
    }

    if (host.match(/simplemovinglabor\.com/i)) {
        return brands.find(b => b.brand === BrandType.SmlMarketplace)!;
    }
    if (host.match(/movingstaffers\.com/i)) {
        return brands.find(b => b.brand === BrandType.MsMarketplace)!;
    }
    return {
        brand: BrandType.Unknown,
        companyName: '',
        abbrev: 'unknown',
        icon: '',
        logoUrl: '',
        brandPhone: '',
        cleanPhone: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumbers: [],
        gtmScript: '',
    };
}
